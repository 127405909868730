import classNames from 'classnames'
import { focusRing } from '~/utils/styles'
import { useEffect, useRef } from 'react'
import { AlertIcon } from '~/theme/icons'

export interface AlertProps {
  title?: string
  message?: string
  children?: React.ReactNode
  showIcon?: boolean
  className?: string
  autoFocus?: boolean
}

export const Alert = ({
  title,
  message,
  showIcon = true,
  className,
  // warning: autoFocus should NOT be used if the <Alert> is visible when the page loads.
  // https://www.boia.org/blog/accessibility-tips-be-cautious-when-using-autofocus
  // It should only be used to bring alerts into view after a user action, like a form submission.
  autoFocus = false,
  children,
}: AlertProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && autoFocus) {
      // scroll smoothly to ref
      ref.current.scrollIntoView({ behavior: 'smooth' })
      ref.current.focus({ preventScroll: true })
    }
  }, [
    autoFocus,
    // scroll to ref when data changes (this might be the same code/message, but a new submission)
    title,
    message,
  ])

  return (
    <section
      className={classNames(
        'shadow-1-l scroll-m-10 rounded-lg border-2 border-yellow-500 bg-yellow-50 p-4 ring-4 ring-yellow-50',
        focusRing,
        className,
      )}
      ref={ref}
      tabIndex={-1}
    >
      <div className='flex gap-3'>
        {showIcon && (
          <AlertIcon
            className='w-6 flex-shrink-0 text-yellow-600'
            aria-hidden
          />
        )}
        <div className='flex flex-col justify-center gap-2'>
          {title && (
            <h3 className='text-body1 font-medium text-yellow-600'>{title}</h3>
          )}
          {message && (
            <div className='text-body2 text-yellow-900'>{message}</div>
          )}

          {children}
        </div>
      </div>
    </section>
  )
}
